import request from "@/utils/request";

// 奖品列表
export function getAwardInfo(data) {
  return request({
    url: "/marketing-web-api/admin/prize/getPrizeList",
    method: "POST",
    data,
  });
}
// 添加/编辑奖品
export function saveAwardInfo(data) {
  return request({
    url: "/marketing-web-api/admin/prize/save",
    method: "POST",
    data,
  });
}
// 获取奖品详情
export function getAwardInfoDetail(data) {
  return request({
    url: "/marketing-web-api/admin/prize/detail",
    method: "POST",
    data,
  });
}
// 删除奖品
export function delAwardInfo(data) {
  return request({
    url: "/marketing-web-api/admin/prize/delete",
    method: "POST",
    data,
  });
}
// 批量删除奖品
export function delAwardInfos(data) {
  return request({
    url: "/marketing-web-api/admin/prize/deleteByIds",
    method: "POST",
    data,
  });
}
// 盲盒列表
export function getBlindBoxList(data) {
  return request({
    url: "/marketing-web-api/admin/blindbox/getBindBoxList",
    method: "POST",
    data,
  });
}
// 企业列表
export function getFindPageList(data) {
  return request({
    url: "/user-web-api/admin/company/findPageList",
    method: "POST",
    data,
  });
}

// 启用、停用盲盒
export function updateBlindBoxIsDisable(data) {
  return request({
    url: "/marketing-web-api/admin/blindbox/enableBlindBox",
    method: "POST",
    data,
  });
}
// 删除盲盒
export function deleteBlindBox(data) {
  return request({
    url: "/marketing-web-api/admin/blindbox/delete",
    method: "POST",
    data,
  });
}
// 批量删除盲盒
export function deleteBlindBoxAll(data) {
  return request({
    url: "/marketing-web-api/admin/blindbox/deleteByIds",
    method: "POST",
    data,
  });
}
// 添加、编辑盲盒
export function saveOrUpdateBlindBox(data) {
  return request({
    url: "/marketing-web-api/admin/blindbox/save",
    method: "POST",
    data,
  });
}
// 查询盲盒详情
export function getBlindBoxDetail(data) {
  return request({
    url: "/marketing-web-api/admin/blindbox/detail",
    method: "POST",
    data,
  });
}

// 查询盲盒规则
export function getBlindBoxRule(data) {
  return request({
    url: "/marketing-web-api/admin/blindbox/boxRulesDetail",
    method: "POST",
    data,
  });
}
// 添加、编辑盲盒规则
export function saveOrUpdateBlindBoxRule(data) {
  return request({
    url: "/marketing-web-api/admin/blindbox/saveBoxRules",
    method: "POST",
    data,
  });
}
// 获取中奖列表
export function getBlindBoxPrizeList(data) {
  return request({
    url: "/marketing-web-api/admin/winning/getList",
    method: "POST",
    data,
  });
}
// 兑换奖品
export function exchangePrize(data) {
  return request({
    url: "/marketing-web-api/admin/winning/exchange",
    method: "POST",
    data,
  });
}
// 删除中奖记录
export function deletePrize(data) {
  return request({
    url: "/marketing-web-api/admin/winning/delete",
    method: "POST",
    data,
  });
}
// 批量删除中奖记录
export function deletePrizeALL(data) {
  return request({
    url: "/marketing-web-api/admin/winning/deleteByIds",
    method: "POST",
    data,
  });
}
// 根据id导出中奖记录
export function exportList(data) {
  return request({
    url: "/marketing-web-api/admin/winning/exportList",
    method: "POST",
    data,
    responseType: "blob",
  });
}
// 启用/停用奖品
export function enablePrize(data) {
  return request({
    url: "/marketing-web-api/admin/prize/enablePrize",
    method: "POST",
    data,
  });
}
